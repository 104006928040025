import React, { Component } from 'react'
import './../styles/UnitSelector.scss';

class UnitSelector extends Component {
  state = {
    isZoomed: false,
  }

  render() {
    const { 
      section,
      sectionType,
      plot,
      units,
      scrollRef,
      getUnitType,
      setCurrentUnitID,
      filtersBedrooms,
      setFiltersBedrooms,
      filtersAvailable,
      toggleFiltersAvailable,
    } = this.props

    const unitsOfThisSection = units.filter(
      unit => unit.section_id === section.id
    );

    const imageMapAreas = sectionType.map_areas ?
      JSON.parse(sectionType.map_areas)
      : [];

    const sectionClassName = this.state.isZoomed ?
      "unitSelector zoomed" : "unitSelector";

    return (
      <section className={sectionClassName} ref={scrollRef}>
        <h2 className="header">
          Seleccione un departamento
        </h2>
        
        <div className="subheader">
          Nivel {section.name} – {plot.name}
        </div>

        <div className="buttonWrapper">
          <div className="toggleZoom"
            onClick={() => 
              this.setState({isZoomed: !this.state.isZoomed})
            }
          >
          </div>
        </div>

        <div className="container">
          <div key={'section' + section.id} className="imageWrapper">
            <img
              src={'images/sections/'+sectionType.image} 
              alt={sectionType.name}
            />
            {Array.isArray(imageMapAreas) && 
              imageMapAreas.map(
                (area) => {

                  // Map unit types for this floor with actual units
                  const unitTypeID = area.unit_type_id;
                  const subType = area.subtype;
                  let unit = unitsOfThisSection.find(
                    unit => 
                      unit.unit_type_id === unitTypeID
                      && (!subType || unit.subtype === subType)
                      && getUnitType(unit.unit_type_id).bedrooms === filtersBedrooms
                  );

                  if( unit && (!filtersAvailable || 'available' === unit.state ) ) {
                    // Show button to select this unit
                    return <div key={'unit' + unit.id}
                      className={"name "+unit.state}
                      onClick={() => {
                          if('unavailable' !== unit.state) { setCurrentUnitID(unit.id) }
                        }
                      }
                      style={{
                        left: area.x + '%',
                        top: area.y + '%',
                      }}
                    >
                      {unit.name}
                    </div>;
                  } else {
                    return null;
                  }
                }
              )
            }
          </div>
        </div>

        <div className="sectionTypeLegend">
          <div className="inputContainer">
            <input type="radio" name="unitFilterBedrooms" value="1"
              className="unitFilterBedrooms" checked={filtersBedrooms === 1} 
              onChange={() => setFiltersBedrooms(1)}
            />
            <label htmlFor="unitFilterBedrooms">1 recámara</label>
          </div>
          <div className="inputContainer">
            <input type="radio" name="unitFilterBedrooms" value="2"
              className="unitFilterBedrooms" checked={filtersBedrooms === 2} 
              onChange={() => setFiltersBedrooms(2)}
            />
            <label htmlFor="unitFilterBedrooms">2 recámaras</label>
          </div>
          <div className="inputContainer">
            <input type="radio" name="unitFilterBedrooms" value="3"
              className="unitFilterBedrooms" checked={filtersBedrooms === 3}
              onChange={() => setFiltersBedrooms(3)}
            />
            <label htmlFor="unitFilterBedrooms">3 recámaras</label>
          </div>
          <div className="inputContainer">
            <input type="checkbox" name="unitFilterAvailable" value="true"
              className="unitFilterAvailable" checked={filtersAvailable}
              onChange={() => toggleFiltersAvailable()}
            />
            <label htmlFor="unitFilterAvailable">Sólo disponibles</label>
          </div>
        </div>
      </section>
    )
  }
}

export default UnitSelector