import React, { Component } from 'react'
import './../styles/SectionSelector.scss';
import Section from './Section';
import Plot from './Plot';

class SectionSelector extends Component {
  render() {
    const {
      plots,
      sections,
      currentPlotID,
      currentSectionID,
      getCurrentPlotImage,
      setCurrentPlotID,
      getSectionType,
      setCurrentSectionID
    } = this.props

    return (
      <section className="sectionSelector">
        <h2>Seleccione un nivel</h2>

        <div className="currentPlot">
          <div className="imageWrapper">
            {getCurrentPlotImage()}
          </div>
        </div>

        <div className="plots">
          {plots.map( (plot, index) => {
              const selectedSections = sections.filter( section => plot.id === section.plot_id );

              return (
                <div className="plotWrapper" key={index}>
                  <Plot
                    key={index}
                    plot={plot}
                    isCurrent={plot.id === currentPlotID}
                    setCurrentPlotID={setCurrentPlotID}
                  />
                  <div className="sections">
                    {selectedSections.map( (section, index) =>
                      <Section
                        key={index}
                        section={section}
                        isCurrent={section.id === currentSectionID}
                        sectionType={getSectionType(section.section_type_id)}
                        setCurrentSectionID={setCurrentSectionID}
                      />
                    )}
                  </div>
                </div>
              )
            }
          )}
        </div>
      </section>
    )
  }
}

export default SectionSelector