import React, { Component } from 'react'
import './../styles/Section.scss';

class Section extends Component {
  render() {
    const { section, isCurrent, setCurrentSectionID } = this.props

    let className = 'section' + (isCurrent ? ' current' : '');

    return (
      <div className={className} onClick={() => setCurrentSectionID(section.id)}>
        <span className="button">{section.name}</span>
      </div>
    )
  }
}

export default Section