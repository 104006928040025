import React, {Component} from 'react';
import './../styles/App.scss';
import SectionSelector from './SectionSelector';
import UnitSelector from './UnitSelector';
import Unit from './Unit';
import ContactForm from './ContactForm';
import Quotation from './Quotation';
import Login from './Login';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link // unused for now
} from "react-router-dom";

class App extends Component {
  state = {
    // ==== App starting state ====
    currentPlotID:  1,
    currentSectionID:  0,
    currentUnitID:  0,
    filtersBedrooms: 2,
    filtersAvailable: true,
    isLoaded: false,
    isAuthenticated: false,
    error: null,
    data: [],
  }

  constructor() {
    super();
    this.refUnitSelector = React.createRef();
    this.refCurrentUnit = React.createRef();
    this.refContactForm = React.createRef();

    // Load config
    this.apiHost = window.config.apiURL;
  }

  setCurrentPlotID = id => {  
    this.setState({
      currentPlotID: id,
      currentSectionID: 0,
      currentUnitID: 0,
    })
  }

  setCurrentSectionID = id => {
    const section = this.getSection(id);
    this.setState(
      {
        currentSectionID: id,
        currentPlotID: section.plot_id,
        currentUnitID: 0,
      },
      () => this.refUnitSelector.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    );
  }

  setCurrentUnitID = id => {  
    this.setState(
      {
        currentUnitID: id,
      },
      () => this.refCurrentUnit.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    )
  }
  
  getSectionType = id => {
    return this.state.data.sectionTypes.find( sectionType => sectionType.id === id );
  }

  getUnitType = id => {
    return this.state.data.unitTypes.find( unitType => unitType.id === id );
  }

  getPlot = id => {
    return this.state.data.plots.find( plot => plot.id === id );
  }

  getSection = id => {
    return this.state.data.sections.find( section => section.id === id );
  }

  getUnit = id => {
    return this.state.data.units.find( unit => unit.id === id );
  }

  getCurrentPlotImage = () => {
    if(!this.state.currentPlotID) return false;
    const plot = this.getPlot( this.state.currentPlotID );
    return (<img src={'images/plots/'+plot.image} alt={plot.name} />);
  }

  getCurrentUnitImage = () => {
    if(!this.state.currentUnitID) return false;
    const unitType = this.getUnitType( this.getUnit(this.state.currentUnitID).unit_type_id );
    return (<img src={'images/units/'+unitType.image} alt={unitType.name} />);
  }

  toggleFiltersAvailable = () => {
    this.setState({filtersAvailable: !this.state.filtersAvailable})
    console.log(this.state)
  }

  componentDidMount() {
    // Get application data
    fetch(this.apiHost + "/get")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  // Deprecated
  svgAbsToRelStyles(coords, w, h) {
    let sumX = 0;
    let sumY = 0;
    const pairList = coords.split(',').map( (str) => {
      const pair = (str.split(' ')).map( coord => parseFloat(coord) );
      const x = ((pair[0] / w * 100));
      const y = ((pair[1] / h * 100));
      const point = x + '% ' + y + '%';      
      sumX += x;
      sumY += y;
      return point;
    });
    return {
      clipPath: 'polygon(' + pairList + ')',
      // Polyon average to get rough center for name tag
      left: sumX / pairList.length + '%',
      top: (sumY / pairList.length) - 5 + '%',
    };
  }

  // Deprecated
  getUnitSelector = () => {
    // if(!this.state.currentSectionID) return false;
    const section = this.getSection(this.state.currentSectionID);
    const sectionType = this.getSectionType(section.section_type_id);

        const unitsOfThisSection = this.state.data.units.filter(
          unit => unit.section_id === section.id
        );
        
        // OLD
        // const imageMapAreas = [
        //   {unit_type_id: 10, area: '140px 172px,127px 291px,139px 298px,212px 299px,265px 272px,264px 170px'}, // Type E
        //   {unit_type_id: 4, area: '265px 271px,376px 271px,376px 174px,264px 174px'}, // Type B1
        //   {unit_type_id: 5, area: '377px 271px,471px 270px,477px 174px,377px 173px'}, // Type B2
        //   {unit_type_id: 12, area: '210px 581px,271px 689px,204px 724px,82px 787px,70px 776px,81px 666px,200px 606px,195px 589px'}, // Type F
        // ];
        
        // NEW
        // const imageMapAreas = [
        //   {unit_type_id: 10, x: 23.9, y: 26.3}, // Type E
        //   {unit_type_id: 4, x: 40, y: 22.8}, // Type B1
        //   {unit_type_id: 5, x: 53.2, y: 22.75}, // Type B2
        //   {unit_type_id: 12, x: 23.9, y: 26.3}, // Type F
        // ];

        const imageMapAreas = sectionType.map_areas ? JSON.parse(sectionType.map_areas) : [];
        return <div key={'section' + section.id} className="imageWrapper">
          <img
            className="unitSelector"
            src={'images/sections/'+sectionType.image} 
            alt={sectionType.name}
          />
          {Array.isArray(imageMapAreas) && 
            imageMapAreas.map(
              (area) => {
                // Find exact unit corresponding to this map area in this section
                const unitTypeID = area.unit_type_id;
                let unit = unitsOfThisSection.find(
                  unit => 
                    unit.unit_type_id === unitTypeID
                    && this.getUnitType(unit.unit_type_id).bedrooms === this.state.filtersBedrooms
                );
                if( unit && (!this.state.filtersAvailable || 'available' === unit.state ) ) {
                  // const imageWidth = 800;
                  // const imageHeight = 800;
                  // const styles = this.svgAbsToRelStyles(area.area, imageWidth, imageHeight);
                  // <div
                  //   key={'unit' + unit.id} className="mapArea"
                  //   style={{
                  //     clipPath: styles.clipPath
                  //   }}
                  // ></div>
                  return <div key={'unit' + unit.id}
                    className={"name "+unit.state}
                    onClick={() => {
                        if('unavailable' !== unit.state) { this.setCurrentUnitID(unit.id) }
                      }
                    }
                    style={{
                      left: area.x + '%',
                      top: area.y + '%',
                    }}
                  >
                    {unit.name}
                  </div>;
                } else {
                  return null;
                }
              }
            )
          }
        </div>
      // })}
      // </>
    // );
  }

  render() {
    const { error, isLoaded } = this.state;
    
    if (error) {
      return <div className="splash error">
        <div className="logo"></div>
        <p>
          <b>Error</b><br />
          Lo sentimos – no se pudo iniciar el cotizador<br />
          <span className="details">({error.message})</span>
        </p>
      </div>;
    
    } else if (!isLoaded) {
      return <div className="splash loading">
        <div className="logo"></div>
        <p>
          Iniciando...
        </p>
      </div>;
    
    } else {      
      const { plots, sections } = this.state.data;
      const currentSection = this.getSection(this.state.currentSectionID);
      const currentUnit = this.getUnit(this.state.currentUnitID);

      return (
        <Router>
          <div className="App">
            <Switch>
              <Route path="/cotizacion">
                {
                  // Quotation form
                  this.state.isAuthenticated && <>
                    <header className="App-header">
                      <h1>Propuesta de inversión</h1>
                    </header>
                    <Quotation
                      units={this.state.data.units}
                      unitTypes={this.state.data.unitTypes}
                      getUnit={this.getUnit}
                      user={this.state.user}
                      getUnitType={this.getUnitType}
                      action={this.apiHost + "/quotation"}
                    />
                  </>
                }
                
                {
                  // Login form
                  !this.state.isAuthenticated &&
                  <Login
                    action={this.apiHost + "/authenticate"}
                    setAuthenticated={(user) => this.setState({user, isAuthenticated: true})}
                  />
                }

                <footer className="appFooter quotationMode">
                </footer>

              </Route>
              
              <Route path="/">
                <header className="App-header">
                  <h1>Disponibilidad</h1>
                </header>

                <SectionSelector 
                  plots={plots}
                  sections={sections}
                  currentPlotID={this.state.currentPlotID}
                  currentSectionID={this.state.currentSectionID}
                  getCurrentPlotImage={this.getCurrentPlotImage}
                  setCurrentPlotID={this.setCurrentPlotID}
                  getSectionType={this.getSectionType}
                  setCurrentSectionID={this.setCurrentSectionID}
                />
                

                {
                  this.state.currentSectionID !== 0 &&
                  <UnitSelector
                    section={currentSection}
                    sectionType={this.getSectionType(currentSection.section_type_id)}
                    plot={this.getPlot(this.state.currentPlotID)}
                    units={this.state.data.units}
                    setCurrentUnitID={this.setCurrentUnitID}
                    scrollRef={this.refUnitSelector}
                    getUnitType={this.getUnitType}
                    filtersBedrooms={this.state.filtersBedrooms}
                    setFiltersBedrooms={(value) => this.setState({filtersBedrooms: value})}
                    filtersAvailable={this.state.filtersAvailable}
                    toggleFiltersAvailable={this.toggleFiltersAvailable}
                  />
                }

                {
                  this.state.currentUnitID !== 0 &&
                  <Unit
                    unit={currentUnit}
                    getUnitType={this.getUnitType}
                    getCurrentUnitImage={this.getCurrentUnitImage}
                    scrollRef={this.refCurrentUnit}
                  />
                }
                
                {
                  this.state.currentUnitID !== 0 &&
                  <ContactForm
                    action={this.apiHost + "/contact"}
                    selectedUnit={this.getUnit(this.state.currentUnitID).name}
                    scrollRef={this.refContactForm}
                  />
                }

                {
                  this.state.currentUnitID === 0 &&
                  <footer className="appFooter">
                  </footer>
                }
              </Route>

            </Switch>
          </div>
        </Router>
      )
    }
  }
}

export default App