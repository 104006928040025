import React, { Component } from 'react'
import './../styles/Login.scss';
var md5 = require('md5');

class Login extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      username: '',
      password: '',
      isLoading: false,
      error: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({isLoading: true, success: false, error: false})

    // Build query string
    const token = md5(`${this.state.username}${this.state.password}`);
    const queryString = `?username=${encodeURIComponent(this.state.username)}&password=${encodeURIComponent(this.state.password)}&token=${token}`

    fetch(this.props.action + queryString, {
      method: 'GET',
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoading: false, error: false, success: true,
        });
        if(result.response === true) {
          this.setState({
            username: '', password: ''
          });
          const user = {
            name: result.name,
            email: result.email,
            telephone: result.telephone,
            telephone2: result.telephone2,
          }
          this.props.setAuthenticated(user);
        }
      },
      (error) => {
        this.setState({error, isLoading: false, success: false})
      }
    )
  }

  render() {
    return (
      <div className="login">
        <h1>
          ¡Hola!
        </h1>
        <form onSubmit={this.handleSubmit}>
          <div className="inputContainer">
            <input name="username" placeholder="Usuario" required={true} value={this.state.username} onChange={this.handleChange} type="text" />
          </div>
          <div className="inputContainer">
            <input name="password" placeholder="Contraseña" required={true} value={this.state.password} onChange={this.handleChange} type="password" />
          </div>
          <div className="inputContainer submit">
            {!this.state.isLoading && <input type="submit" value="Iniciar sesión" />}
            {this.state.isLoading && <div className="message">Procesando...</div>}
            {this.state.error && <div className="message">No se puedo iniciar la sesión – favor de intentar otra vez</div>}
          </div>
        </form>
      </div>
    )
  }
}

export default Login