import React, { Component } from 'react'
import './../styles/Unit.scss';

class Unit extends Component {
  render() {
    const { unit, getUnitType, getCurrentUnitImage, scrollRef } = this.props
    const unitType = getUnitType(unit.unit_type_id);

    return (
      <section className="currentUnit" ref={scrollRef}>
        <h2 className="header">
          Departamento
        </h2>
        
        <div className="subheader">
          {unit.name} Tipo {unitType.name}
        </div>

        <div className="contentWrapper">
          <div className="imageWrapper">
            {getCurrentUnitImage()}
          </div>

          <ul className="specs">
            <li>
              {unit.area_interior_m2} m<sup>2</sup> Interiores
            </li>
            <li>
              {unit.area_exterior_m2} m<sup>2</sup> Terraza
            </li>
            <li>
              {(unit.area_interior_m2 + unit.area_exterior_m2).toFixed(2)} m<sup>2</sup> Total
            </li>
            <li>
              {unitType.bathrooms} Baños
            </li>
            <li>
              {unitType.bedrooms} Recámaras
            </li>
            <li>
              {unitType.parking_spaces} Estacionamientos
            </li>
          </ul>
        </div>
      </section>
    )
  }
}

export default Unit