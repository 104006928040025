import React, { Component } from 'react'
import './../styles/ContactForm.scss';
var md5 = require('md5');

class ContactForm extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      name: '',
      email: '',
      telephone: '',
      isLoading: false,
      error: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({isLoading: true, success: false, error: false})

    // Build query string
    const token = md5(`${this.state.name}${this.state.email}${this.state.telephone}${this.props.selectedUnit}`);
    const queryString = `?name=${encodeURIComponent(this.state.name)}&email=${encodeURIComponent(this.state.email)}`
      + `&telephone=${encodeURIComponent(this.state.telephone)}&selectedUnit=${encodeURIComponent(this.props.selectedUnit)}&token=${token}`;

    fetch(this.props.action + queryString, {
      method: 'GET',
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        this.setState({
          isLoading: false, error: false, success: true,
          name: '', email: '', telephone: '',
        })
      },
      (error) => {
        this.setState({error, isLoading: false, success: false})
      }
    )
  }

  render() {
    const { scrollRef } = this.props;

    return (
      <section className="contactForm" ref={scrollRef}>
        <h2 className="header">
          Para mayor información
        </h2>
        
        {this.state.error && <div className="error message">
          <b>Error</b><br />
          Lo sentimos – no se pudo enviar sus datos.<br />
          Favor de contactarse directo con sergio@pisodoce.mx<br />
          ¡Gracias!
        </div>}

        {this.state.success && <div className="success message">
          <b>¡Gracias!</b><br />
          Su mensaje ha sido enviado y le contactaremos pronto. Saludos.
        </div>}
        
        <form onSubmit={this.handleSubmit}>
          <div className="inputContainer">
            <input name="name" placeholder="Nombre" required={true} value={this.state.name} onChange={this.handleChange} type="text" />
          </div>
          <div className="inputContainer">
            <input name="email" placeholder="E-mail" required={true} value={this.state.email} onChange={this.handleChange} type="email" />
          </div>
          <div className="inputContainer">
            <input name="telephone" placeholder="Teléfono" value={this.state.telephone} onChange={this.handleChange} type="text" />
          </div>
          <div className="inputContainer submit">
            {!this.state.isLoading && <input type="submit" value="Enviar" />}
            {this.state.isLoading && <div>Enviando...</div>}
          </div>
        </form>
      </section>
    )
  }
}

export default ContactForm