import React, { Component } from 'react'
import './../styles/Plot.scss';

class Plot extends Component {
  render() {
    const { plot, isCurrent } = this.props

    let className = 'plot' + (isCurrent ? ' current' : '');

    return (
      // To use this as a button to change plots, add:
      // onClick={() => setCurrentPlotID(plot.id)}
      // NOTE: also get setCurrentPlotID from props above
      <div className={className}>
        {plot.name}
      </div>
    )
  }
}

export default Plot