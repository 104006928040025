import React, { Component } from 'react'
import './../styles/Quotation.scss';
import NumberFormat from 'react-number-format';
var md5 = require('md5');
// var Finance = require('financejs');

// Default percentages and interests
const defaultDownPaymentPercent = 10;
const defaultResidualValuePercent = 10;
const defaultInterestDiscountPercent = 5.5;
const defaultInterestOverpricePercent = 11.5;

class Quotation extends Component {
  constructor(props) {
    // This doesn't seem to be deprecated even though flagged as such.
    // see https://stackoverflow.com/questions/63856962/understanding-why-super-is-deprecated-in-a-react-class-component
    super(props); // Could omit props argument, but then this.props wouldn't be available
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Build options for month selectors
    const maxMonths = 25;
    const {months1, months2} = this.getMonthLists(maxMonths)
    const monthStart = JSON.stringify(months2[0])
    const monthEnd = JSON.stringify(months1[11])

    // Build initial state
    this.state = {
      // Initial unit
      currentUnitID: this.props.units[0].id,
      // Initial dates & installments
      months1,
      months2,
      monthStart,
      monthEnd,
      installments: 12,
      installmentData: [],
      standardInstallmentAmount: 0,
      // Payment settings
      // TODO: add defaults for percentages and interest, so they can be reset when changing payment scheme
      downPaymentPercent: defaultDownPaymentPercent,
      residualValuePercent: defaultResidualValuePercent,
      discountPercent: 0,
      yearlyInterestPercent: defaultInterestDiscountPercent,
      paymentScheme: "normal",
      interestType: "discount",
      paymentTotal: 0,
    };
  }

  componentDidMount() {
    this.getInstallmentData()
  }

  getInstallmentData() {
    var installmentData = []
    var amount
    const numberOfInstallments = this.state.installments
    const yearlyInterestPercent = this.state.yearlyInterestPercent
    const priceCents = this.getUnit(this.state.currentUnitID).price_cents
    const downPaymentAmount = this.state.downPaymentPercent / 100 * priceCents
    const residualValueAmount = this.state.residualValuePercent / 100 * priceCents

    // Buyer adjusts installment amounts
    // TODO: this is unused for now (dummy data below)
    // if('custom2' === this.state.paymentScheme) {
    //   installmentData = [
    //     {amount: 123654.23, month: this.state.months2[0]},
    //     {amount: 994647.23, month: this.state.months2[1]},
    //   ]
    // }

    // Calculate monthly payments based on percentages
    var discountPercent = 0;
    var newTotal = 0;
    amount = this.getMonthlyPayment(
      priceCents,
      downPaymentAmount,
      numberOfInstallments,
      yearlyInterestPercent,
      residualValueAmount
    )
    const monthStartIndex = JSON.parse(this.state.monthStart).index
    for(let monthIndex = 0; monthIndex < numberOfInstallments; monthIndex++) {
      let monthData = this.state.months2.find(
        (month) => month.index === monthStartIndex + monthIndex
      )
      installmentData.push(
        {amount: amount / 100, month: monthData}
      )
      newTotal += amount;
    }
    
    // If using a discount interest rate, calculate difference with higher interest
    // to see how large the discount is
    if('discount' === this.state.interestType) {
      var overpriceTotal = 0;
      const amount2 = this.getMonthlyPayment(
        priceCents,
        downPaymentAmount,
        numberOfInstallments,
        defaultInterestOverpricePercent,
        residualValueAmount
      )
      overpriceTotal = amount2 * numberOfInstallments
      discountPercent = (overpriceTotal/newTotal - 1) * 100
    }

    this.setState({
      installmentData,
      standardInstallmentAmount: amount,
      discountPercent,
      paymentTotal: newTotal + residualValueAmount + downPaymentAmount,
    })
  }

  // This function mimics Excel's PMT function
  pmtValue(pv, rv, n, i) {
    return (pv - (rv / (Math.pow( 1 + i, n )))) / ( (1 - (1 / (Math.pow( 1 + i, n )))) / i)
  }

  getMonthlyPayment(initialCapital, initialPayment, months, yearlyInterestPercent, residualValue) {
    var monthlyInterest = yearlyInterestPercent / 360 * 30 * 0.01;

    if ( initialCapital === 0 || months === 0 || monthlyInterest === 0 || residualValue >= initialCapital ) {
      return null;
    } else	{
      try {
        return this.pmtValue(initialCapital - initialPayment, residualValue, months, monthlyInterest);
      }
      catch(err) {
        console.log('Error calculating monthly payment: ' + err);
      }
    }	
  }

  getMonthLists(maxMonths) {
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth() + 1;
    let months1 = [];
    for(let month = thisMonth; month <= maxMonths + thisMonth; month++) {
      let year = thisYear + ( Math.ceil(month / 12) - 1 );
      let monthIndex = month % 12 === 0 ? 12 : (month % 12);
      let monthName = monthNames[monthIndex - 1];
      months1.push({key: `${year}-${monthIndex}`, value: `${monthName} ${year}`, index: month});
    }
    // Remove last month from start date list
    let months2 = [...months1];
    months2.pop();
    // Remove first month from end date list
    months1.shift();

    return {months1, months2}
  }

  handleChange(event) {
    const target = event.target;
    const value = 'number' === target.getAttribute('type') ?
      parseFloat(target.value) : target.value;
    const name = target.name;

    if(name === 'monthEnd' || name === 'monthStart') {
      this.setState(
        {
          [name]: value
        },
        () => {
          // Get month difference
          const monthStartIndex = JSON.parse(this.state.monthStart).index
          const monthEndIndex = JSON.parse(this.state.monthEnd).index
          if(monthEndIndex - monthStartIndex < 1) {
            // Set end date to one more than start date
            const newEndMonth = this.state.months1.find((month) => month.index === monthStartIndex + 1)
            this.setState(
              {
                monthEnd: JSON.stringify(newEndMonth),
                installments: 1,
              },
              () => this.getInstallmentData()
            );
          } else {
            this.setState(
              {
                installments: monthEndIndex - monthStartIndex,
              },
              () => this.getInstallmentData()
            );
          }
        }
      );
    } else {
      // Interest default percentages
      if(name === 'interestType') {
        if('discount' === value) {
          this.setState({yearlyInterestPercent: defaultInterestDiscountPercent})
        } else if('overprice' === value) {
          this.setState({yearlyInterestPercent: defaultInterestOverpricePercent})
        }
      }

      // Payment scheme default percentages
      if(name === 'paymentScheme') {
        if('normal' === value) {
          this.setState({
            downPaymentPercent: defaultDownPaymentPercent,
            residualValuePercent: defaultResidualValuePercent,
          })
        }
      }

      this.setState(
        {
          [name]: value
        },
        () => this.getInstallmentData()
      )
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({isLoading: true, success: false, error: false})

    // Build query string
    // TODO: need to include authentication data
    const submitData = {
      currentUnitID: this.state.currentUnitID,
      monthStart: this.state.monthStart,
      installments: this.state.installments,
      standardInstallmentAmount: this.state.standardInstallmentAmount,
      downPaymentPercent: this.state.downPaymentPercent,
      residualValuePercent: this.state.residualValuePercent,
      interestType: this.state.interestType,
      yearlyInterestPercent: this.state.yearlyInterestPercent,
      user: this.props.user,
      paymentScheme: this.state.paymentScheme,
      paymentTotal: this.state.paymentTotal,
      clientName: this.state.clientName,
    }
    const token = md5(JSON.stringify(submitData));
    const queryString = `?data=${encodeURIComponent(JSON.stringify(submitData))}&token=${token}`;

    fetch(this.props.action + queryString, {
      method: 'GET',
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        this.setState({
          isLoading: false, error: false, success: true,
        })
      },
      (error) => {
        this.setState({error, isLoading: false, success: false})
      }
    )
  }

  getUnit = id => {
    return this.props.units.find( unit => unit.id === parseInt(id) );
  }

  getUnitType = id => {
    return this.props.unitTypes.find( unitType => unitType.id === parseInt(id) );
  }

  render() {
    return (
      <form className="quotation" onSubmit={this.handleSubmit}>
        <section>
          <fieldset>
            <div className="inputContainer">
              <label>Número de departamento</label>
              <select value={this.state.currentUnitID} name="currentUnitID" onChange={this.handleChange}>
                {this.props.units.map( (unit) => <option value={unit.id} key={unit.id}>{unit.name}</option> )}
              </select>
            </div>
            <div className="inputContainer">
              <label>Nombre de cliente</label>
              <input
                    value={this.state.clientName}
                    name="clientName"
                    type="text"
                    placeholder="Nombre y apellidos"
                    onChange={this.handleChange} />
            </div>
            <div className="inputContainer">
              <label>Mes de venta</label>
              <select value={this.state.monthStart} name="monthStart" onChange={this.handleChange}>
                {this.state.months2.map( (month) => <option value={JSON.stringify(month)} key={'start'+month.key}>{month.value}</option> )}
              </select>
            </div>
            <div className="inputContainer">
              <label>Mes fin</label>
              <select value={this.state.monthEnd} name="monthEnd" onChange={this.handleChange}>
                {this.state.months1.map( (month, index) => <option data-months={index+1} value={JSON.stringify(month)} key={'end'+month.key}>{month.value}</option> )}
              </select>
            </div>
            <div className="inputContainer">
              <label>Tipo de tasa</label>
              <select value={this.state.interestType} name="interestType" onChange={this.handleChange}>
                <option value="discount" key="discount">Descuento</option>
                <option value="overprice" key="overprice">Sobreprecio</option>
              </select>
            </div>
          </fieldset>
          <fieldset>
              <div className="inputContainer">
                <label>Precio inicial</label>
                <div className="value">
                  <NumberFormat
                    value={this.getUnit(this.state.currentUnitID).price_cents / 100}
                    displayType={'text'}
                    decimalScale="2"
                    thousandSeparator={true}
                    prefix={'$'} />
                </div>
              </div>
              <div className="inputContainer">
                <label>Tipología</label>
                <div className="value">
                  {( this.getUnitType(this.getUnit(this.state.currentUnitID).unit_type_id ).name)}
                </div>
              </div>
              <div className="inputContainer">
                <label>Tipo de cotización</label>
                <div className="value">
                  {'discount' === this.state.interestType ? 'Descuento' : 'Sobreprecio' }
                </div>
              </div>
              <div className="inputContainer">
                <label>Tipo de descuento</label>
                <div className="value">
                {'discount' === this.state.interestType ? 'Tasa descuento' : 'Ninguno' }
                </div>
              </div>
              <div className="inputContainer">
                <label>Tasa de descuento</label>
                <div className="value">
                  {defaultInterestDiscountPercent.toFixed(2)}%
                </div>
              </div>
              <div className="inputContainer">
                <label>Tasa sobreprecio</label>
                <div className="value">
                {defaultInterestOverpricePercent.toFixed(2)}%
                </div>
              </div>
            </fieldset>
        </section>
        <section>
          <fieldset>
            <h2>Seleccione una forma de pago</h2>
            <div className="inputContainer">
              <select value={this.state.paymentScheme} name="paymentScheme" onChange={this.handleChange}>
                <option value="normal" key="normal">Tradicional</option>
                <option value="custom1" key="custom1">Forma de pago 1</option>
              </select>
            </div>
          </fieldset>
          <fieldset>
              <div className="inputContainer">
                <label>Enganche</label>
                {
                  ('normal' === this.state.paymentScheme || 
                  'custom2' === this.state.paymentScheme) &&
                  <div className="value">
                    {this.state.downPaymentPercent.toFixed(2)}%
                  </div>
                }
                {
                  'custom1' === this.state.paymentScheme &&
                  <><input
                    value={this.state.downPaymentPercent}
                    name="downPaymentPercent"
                    type="number"
                    min="0"
                    step="0.01"
                    onChange={this.handleChange} /> %
                  </>
                }
              </div>
              <div className="inputContainer">
                <label>Remanente</label>
                {
                  ('normal' === this.state.paymentScheme || 
                  'custom2' === this.state.paymentScheme) &&
                  <div className="value">
                    {this.state.residualValuePercent.toFixed(2)}%
                  </div>
                }
                {
                  'custom1' === this.state.paymentScheme &&
                  <><input
                    value={this.state.residualValuePercent}
                    name="residualValuePercent"
                    type="number"
                    min="0"
                    step="0.01"
                    onChange={this.handleChange} /> %
                  </>
                }
              </div>
              <div className="inputContainer">
                <label>Firma de escrituración</label>
                <div className="value">
                  {(100 - this.state.residualValuePercent - this.state.downPaymentPercent).toFixed(2)}%
                </div>
              </div>
              <div className="inputContainer">
                <label>Número de meses</label>
                <div className="value">
                  {this.state.installments}
                </div>
              </div>
              <div className="inputContainer">
                <label>Descuento</label>
                <div className="value">
                  {this.state.discountPercent.toFixed(2)}%
                </div>
              </div>

              <div className="inputContainer total">
                <label>
                  Total: <NumberFormat
                    value={this.state.paymentTotal / 100}
                    displayType={'text'}
                    decimalScale="2"
                    thousandSeparator={true}
                    prefix={'$'} />
                </label>
              </div>
            </fieldset>
          <fieldset>
            <ul>
              <li>
                <span>Mensualidades</span>
              </li>
                {this.state.installmentData.map(
                  (installment) =>
                  <li key={'installment'+installment.month.key}>
                    <span>
                      <NumberFormat
                        value={installment.amount} displayType={'text'} decimalScale="2" thousandSeparator={true} prefix={'$'} />
                    </span>
                    <span>
                      { installment.month.value.substring(0, 3) }&nbsp;
                      { installment.month.value.substring(installment.month.value.length - 4) }
                    </span>
                  </li>
                )}
            </ul>
            <div className="inputContainer submit">
              {
                !this.state.isLoading &&
                <input type="submit" value="Calcular descuento" />
              }
              {
                this.state.isLoading &&
                <div className="loading message">
                  Procesando...
                </div>
              }
              {
                this.state.success &&
                <div className="success message">
                  La cotización fue enviado a tu e-mail
                </div>
              }
              {
                this.state.error &&
                <div className="error message">
                  Lo sentimos, no se puede procesar la cotización<br />
                  ({this.state.error})
                </div>
              }
            </div>
          </fieldset>
        </section>
      </form>
    )
  }
}

export default Quotation